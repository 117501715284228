import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/paginated-layout.js";
import { Subpage, Callout } from "course-components";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Subpage slug="intro-to-markup" mdxType="Subpage">
      <h3>{`Introduction to Intelligent Markup`}</h3>
      <p>{`With an overview of HTML under your belt from the last module, you should recall that HTML is simply a "language" we use for marking content with tags that provide structure and meaning to the content for the context of the World Wide Web.`}</p>
      <p>{`The basic markup we've covered so far allowed us to create a valid HTML document:`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "html"
      }}><pre parentName="div" {...{
          "style": {
            "counterReset": "linenumber NaN"
          },
          "className": "language-html line-numbers"
        }}><code parentName="pre" {...{
            "className": "language-html"
          }}><span parentName="code" {...{
              "className": "token doctype"
            }}>{`<!DOCTYPE html>`}</span>{`
`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`html`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`head`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`title`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`Page Title goes here`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`title`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`meta`}</span>{` `}<span parentName="span" {...{
                "className": "token attr-name"
              }}>{`charset`}</span><span parentName="span" {...{
                "className": "token attr-value"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`=`}</span><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span>{`UTF-8`}<span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`"`}</span></span>{` `}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`/>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`head`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`body`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
        Content goes here ...
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`body`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`html`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span></code><span parentName="pre" {...{
            "aria-hidden": "true",
            "className": "line-numbers-rows",
            "style": {
              "whiteSpace": "normal",
              "width": "auto",
              "left": "0"
            }
          }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
      <p>{`But this is pretty boring, right? No interesting content here. So how do we get from here to something more interesting like, say, the content on this page, with headings, paragraphs, and lists?`}</p>
      <p>{`Many people have learned basic HTML like we covered in the last module. But what separates the professionals from the newbies is attention to detail and a knack for fine-tuned ability to apply the most appropriate markup possible to the content at hand. Many people overlook these details because they are not complicated. And while they can become second nature to a trained expert, they are also taken for granted by many cursory content writers.`}</p>
      <p>{`These crucial skills come down to this: can you distinguish a heading from a paragraph and a paragraph from a list?`}</p>
    </Subpage>
    <Subpage slug="block-content" mdxType="Subpage">
      <h3>{`Block-level Content Markup`}</h3>
      <p>{`A small set of elements provide the backbone  for all other tags. These are the block-level content tags that include headings, paragraphs, lists, and block quotes. A larger set of inline elements provide the ability to mark more nuanced content.`}</p>
      <h4>{`Headings and Paragraphs`}</h4>
      <Callout number="2.1" term="Paragraphs and headings..." color="alternate" mdxType="Callout">
        <p>{`...form the backbone of well-structured web content. HTML offers the `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`<p>`}</code>{` tag for paragraphs and six levels of headings, `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`<h1>`}</code>{` – `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`<h6>`}</code>{`.`}</p>
      </Callout>
      <Callout lead={true} color="secondary" mdxType="Callout">
        <p>{`Read pp. 40-44 and 52 in Ch. 2 and Ch. 3 from Duckett.`}</p>
      </Callout>
      <p>{`The author has introduced us to a number of tags that help us mark HTML content. He has also introduced the subject of semantic markup. Let’s review some important tags that were covered:`}</p>
      <ul>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`<p>`}</code>{` — Paragraph — This tag is the most basic of tags for text content. If you’re not sure what content is, start by marking it as a paragraph.`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`<h1>`}</code>{` — Primary heading — This tag is used to mark the single most important heading of a section or page. In most sites I build, I use this to mark the branding or site’s name.`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`<h2>`}</code>{` — Secondary heading — This tag is used to mark the direct subheadings under an `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<h1>`}</code>{` tag. In other words, if I was to make an outline of the heading structure (headings, sub-headings, sub-sub headings, etc.) of my web page, `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<h2>`}</code>{` tags would be the point in the outline directly underneath the `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<h1>`}</code>{` tag.`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`<h3>`}</code>{` — Tertiary heading — Like the `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<h2>`}</code>{` tag, this tag marks subheadings, but this time, subheadings that fall directly under the `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<h2>`}</code>{` tags.`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`<h4>`}</code>{`, `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<h5>`}</code>{`, `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<h6>`}</code>{` — Fourth-, Fifth- and Sixth-level headings — These three tags each mark successive levels of subheadings each under the one higher in the “outline.”`}</li>
      </ul>
      <p>{`It may be tempting when you’re learning to use a heading level in order to make a heading look larger or smaller. But this is not semantic! When we’re writing HTML we should be focused on the meaning of the content—the semantics of the content. We will affect the appearance of elements later with CSS (size, color, weight, spacing, font, etc.).`}</p>
      <h4>{`Long Quotations`}</h4>
      <Callout number="2.2" term="Long quotations..." color="alternate" mdxType="Callout">
        <p>{`...can be marked with the `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`<blockquote>`}</code>{` tag.`}</p>
      </Callout>
      <p>{`One final helpful tag to discuss is the `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<blockquote>`}</code>{` tag, which is used to mark a long quotation. If a quotation takes up an entire sentence or more, use `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<blockquote>`}</code>{` to identify it. Check it out:`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "html"
      }}><pre parentName="div" {...{
          "style": {
            "counterReset": "linenumber NaN"
          },
          "className": "language-html line-numbers"
        }}><code parentName="pre" {...{
            "className": "language-html"
          }}><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`blockquote`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`A quiet answer
       turns away wrath.`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`blockquote`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span></code><span parentName="pre" {...{
            "aria-hidden": "true",
            "className": "line-numbers-rows",
            "style": {
              "whiteSpace": "normal",
              "width": "auto",
              "left": "0"
            }
          }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
      <p>{`Notice that the quote is wrapped with a paragraph first and then the paragraph is wrapped with a `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<blockquote>`}</code>{`. If your quotation has several paragraphs, wrap the whole set with one `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<blockquote>`}</code>{`:`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "html"
      }}><pre parentName="div" {...{
          "style": {
            "counterReset": "linenumber NaN"
          },
          "className": "language-html line-numbers"
        }}><code parentName="pre" {...{
            "className": "language-html"
          }}><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`blockquote`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`When wickedness comes, contempt comes also,
       and with dishonor comes disgrace.`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
    `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`The words of a man’s mouth are deep waters;
       the fountain of wisdom is a bubbling brook.`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`</`}</span>{`blockquote`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span></code><span parentName="pre" {...{
            "aria-hidden": "true",
            "className": "line-numbers-rows",
            "style": {
              "whiteSpace": "normal",
              "width": "auto",
              "left": "0"
            }
          }}><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span><span parentName="span"></span></span></pre></div>
      <h4>{`Lists`}</h4>
      <Callout number="2.3" term="List markup..." color="alternate" mdxType="Callout">
        <p>{`...provides an excellent way to mark up related series of data. The `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`<ul>`}</code>{` and `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`<ol>`}</code>{`, and `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`<dl>`}</code>{` elements group series of `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`<li>`}</code>{` elements to form itemized lists and the `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`<dl>`}</code>{` element groups series of `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`<dt>`}</code>{` and `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`<dd>`}</code>{` to form definition lists.`}</p>
      </Callout>
      <p>{`Headings and paragraphs add a good bit of structure to our content and account for a good majority of basic web content. Another set of tags to add to your library is the list markup tags. Lists should be used in HTML content any time you identify a group of items that follow a list-like pattern. Examples include the following:`}</p>
      <ul>
        <li parentName="ul">{`A set of step-by-step instructions`}</li>
        <li parentName="ul">{`A group of products`}</li>
        <li parentName="ul">{`A series of features or highlighted facts`}</li>
        <li parentName="ul">{`... and much more!`}</li>
      </ul>
      <p>{`You’ll discover when you really dig into it, that there are many more lists in our content than we often realize. In fact, one way to make web content more approachable is to break out those lists into structured markup using these tag sets.`}</p>
      <p>{`There are two categories of lists:`}</p>
      <ol>
        <li parentName="ol">{`Itemized lists:`}<ol parentName="li">
            <li parentName="ol">{`Itemized lists are made of a group of list items, `}<code parentName="li" {...{
                "className": "language-text"
              }}>{`<li>`}</code>{` tags grouped either as an ordered list `}<code parentName="li" {...{
                "className": "language-text"
              }}>{`<ol>`}</code>{` or unordered list `}<code parentName="li" {...{
                "className": "language-text"
              }}>{`<ul>`}</code>{`.`}</li>
            <li parentName="ol">{`Use ordered lists to mark a series of items that are presented in a specific and intentional order such as steps in a process. Items grouped in an ordered list `}<code parentName="li" {...{
                "className": "language-text"
              }}>{`<ol>`}</code>{` will appear numbered by default, but CSS can adjust this.`}</li>
            <li parentName="ol">{`Use unordered lists to mark a series of items that form a list but don’t have a particular order to them such as a list of features or a list of products that aren’t sorted in any way. Items grouped in an unordered list `}<code parentName="li" {...{
                "className": "language-text"
              }}>{`<ul>`}</code>{` will appear bulleted by default, but CSS can adjust this.`}</li>
            <li parentName="ol"><code parentName="li" {...{
                "className": "language-text"
              }}>{`<ul>`}</code>{` and `}<code parentName="li" {...{
                "className": "language-text"
              }}>{`<ol>`}</code>{` may only contain `}<code parentName="li" {...{
                "className": "language-text"
              }}>{`<li>`}</code>{` elements as their direct children, but `}<code parentName="li" {...{
                "className": "language-text"
              }}>{`<li>`}</code>{` elements can contain any other markup, including sub-lists. We’ll look at this more later in the course.`}</li>
          </ol></li>
        <li parentName="ol">{`Definition lists:`}<ol parentName="li">
            <li parentName="ol">{`Definition lists contain a series of term-and-definition pairs marked by definition term `}<code parentName="li" {...{
                "className": "language-text"
              }}>{`<dt>`}</code>{` and definition description `}<code parentName="li" {...{
                "className": "language-text"
              }}>{`<dd>`}</code>{` tags and grouped by a definition list `}<code parentName="li" {...{
                "className": "language-text"
              }}>{`<dl>`}</code>{` tag.`}</li>
            <li parentName="ol">{`Use definition lists when you have a set of terms and descriptions such as a vocabulary list or reference list.`}</li>
            <li parentName="ol"><code parentName="li" {...{
                "className": "language-text"
              }}>{`<dl>`}</code>{` tags can only contain `}<code parentName="li" {...{
                "className": "language-text"
              }}>{`<dt>`}</code>{` and `}<code parentName="li" {...{
                "className": "language-text"
              }}>{`<dd>`}</code>{` tags as their direct children.`}</li>
          </ol></li>
      </ol>
    </Subpage>
    <Subpage slug="inline-markup" mdxType="Subpage">
      <h3>{`Inline Content Markup`}</h3>
      <Callout number="2.4" term="Inline content markup..." color="alternate" mdxType="Callout">
        <p>{`...allows for finesse in structuring smaller chunks of content. This larger library of tags add meaning and structure to your content usually inside of block-level elements. A short list of additional tags to know is: `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`<em>`}</code>{`, `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`<strong>`}</code>{`, `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`<q>`}</code>{`, `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`<cite>`}</code>{`, `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`<abbr>`}</code>{`, `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`<sup>`}</code>{`, `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`<sub>`}</code>{`, `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`<i>`}</code>{`, and `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`<b>`}</code>{`.`}</p>
      </Callout>
      <p>{`Now that you have a basic library of typographical markup including headings, paragraphs, and lists, there are a number of tags that add additional finesse.`}</p>
      <Callout lead={true} color="secondary" mdxType="Callout">
        <p>{`Read pp. 45–48 and 51–60 in Ch. 2 of Duckett.`}</p>
        <small>Note that p.50 introduces semantic markup, which we discuss in more detail later in this module. My definition differs slightly from the author’s definition.</small>
      </Callout>
      <p>{`Here is a small set of useful tags that allows you to add more nuanced meaning to your content:`}</p>
      <ul>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`<em>`}</code>{` — Add emphasis to content. The result by default is for text to be italicized.`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`<strong>`}</code>{` — Add strong emphasis to content. The result by default is for text to be bolded.`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`<q>`}</code>{` — Mark a short quotation.`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`<cite>`}</code>{` — Identify the citation or source for a quotation or reference.`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`<abbr>`}</code>{` — Mark an abbreviation or acronym.`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`<sup>`}</code>{` — Mark superscript text such as the “nd” in 2^nd.`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`<sub>`}</code>{` — Mark subscript text such as the “2” on O`}<sub>{`2`}</sub>{`.`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`<i>`}</code>{` — Add italics to content without adding emphasis, such as marking the title of a book. Remember that `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<em>`}</code>{` is better to use when italics are added for actual emphasis.`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`<b>`}</code>{` — Add boldface to content without adding emphasis. Most times, you’ll probably want to use `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<strong>`}</code>{` since bolding is often used to mark content that has strong emphasis. But for cases where you simply want to portray a slightly different voice or style through bolding, this tag can be helpful.`}</li>
      </ul>
      <p>{`Others you may want to look up include `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<br/>`}</code>{`, `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<hr/>`}</code>{`, `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<span>`}</code>{`, `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<ins>`}</code>{`, `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<del>`}</code>{`, `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<dfn>`}</code>{`, `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<pre>`}</code>{`, and `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<code>`}</code>{`.`}</p>
    </Subpage>
    <Subpage slug="semantic-markup" mdxType="Subpage">
      <h3>{`Semantic Markup`}</h3>
      <Callout number="2.5" term="Semantic markup..." color="alternate" mdxType="Callout">
        <p>{`...means using the best possible markup for the content at hand. While the tags are easy to memorize, it takes practice and input from an expert to develop this skill, but it sets the professional apart from the novice.`}</p>
      </Callout>
      <p>{`Even monkeys can apply these basic tags to content. What makes the difference for us is an intelligent application of tags that don’t just affect appearance, but rather, tags that add meaning that matches what the content actually is.`}</p>
      <p><em parentName="p">{`Semantic markup`}</em>{` is markup that adds meaning to content. In other words, true web professionals think critically about the markup they use rather than just throwing this tag or that tag around content. Instead of shortcuts, they strive to use the most appropriate tag. Note that this definition differs from the one Duckett provided on pages 49–50. In the past, authors defined a series of tags as “semantic” and others as “non-semantic” or “presentational.” I’ve come to conclude that what is more core to the ideal of semantic markup is not as much defining some tags as semantic and others as not, but rather, in how and when tags are used. It’s simple: figure out what a block or chunk of content is and apply the best possible tag to match that content’s meaning.`}</p>
      <p>{`What usually gets in the way is that many automated website creation tools use WYSIGYG (what you see is what you get) editors that separate the content editing from the actual markup. While this is helpful for people who don’t understand HTML, many of these tools automate the markup and add programmed best-guess markup instead of intentional human markup.`}</p>
      <p>{`Another factor that compounds the problem is that many users don’t think about content semantically. They’re used to applying formatting to make things look good, and the most common method is to change the font, change the size, change the color, add bolding or italics, or add an underline. But on the web, all of these presentation changes result in more markup unless one understands CSS and HTML well. The result otherwise is messy, unwieldy code. As a budding web professional, you should strive for clean, flexible code. When it comes to semantics, here are some things to watch out for:`}</p>
      <ol>
        <li parentName="ol"><em parentName="li">{`Use specific tags to mark content rather than generic ones.`}</em>{` In your journey you’ll see `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<div>`}</code>{` tags and `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<span>`}</code>{` tags marking blocks that really ought to be paragraphs and headings or other more refined tags.`}</li>
        <li parentName="ol"><em parentName="li">{`Adjust headings to ensure strong hierarchy.`}</em>{` You may be tempted to apply a heading specifically for the way it looks. That’s silly. CSS allows you to alter the way any tag looks, so you should apply a heading that makes sense for the hierarchy of the document instead.`}</li>
        <li parentName="ol"><em parentName="li">{`When appropriate, use headings instead of bolded paragraphs.`}</em>{` Since people are used to creating their own headings by changing a paragraph’s font size and making it bold, you may be tempted to do the same. If you bold an entire paragraph, consider whether you ought instead to just make it a heading. But do make sure it really is a heading first and that it really needs to be bolded.`}</li>
        <li parentName="ol"><em parentName="li">{`Use appropriate inline markup instead of more generic presentational markup.`}</em>{` The `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<i>`}</code>{` and `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<b>`}</code>{` tags are short and sweet, but have limited semantic value. `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<em>`}</code>{` and `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<strong>`}</code>{` are better choices when emphasis or strong emphasis is needed. For example, use `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<i>`}</code>{` to mark a book title (where italics are typically employed, but emphasis is not necessary added), but use `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<em>`}</code>{` to add emphasis (as in the phrase, “I hate lima beans”). Both result in text that is italicized, but `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<em>`}</code>{` is a better choice in the second example because it marks actual emphasized text whereas `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<i>`}</code>{` simply adds italics.`}</li>
      </ol>
      <h4>{`Steps for Applying Semantic Markup`}</h4>
      <p>{`To actually apply the concept of semantic markup in context, follow this process:`}</p>
      <ul>
        <li parentName="ul">{`Assume all blocks are paragraphs to start and apply the `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<p>`}</code>{` tag to each distinct block of text.`}</li>
        <li parentName="ul">{`Read the content you’re intending to markup and analyze its structure. Consider which elements might become headings and plan the heading structure.`}</li>
        <li parentName="ul">{`Based on your analysis, revise the markup to add the appropriate headings.`}</li>
        <li parentName="ul">{`Then consider if any elements form a list. Consider which list type is best, then revise the markup to apply this structure.`}</li>
        <li parentName="ul">{`Finally, take a detailed look to add appropriate inline markup, block quotations, and other semantic markup to enhance the content’s meaning and structure.`}</li>
      </ul>
      <p>{`As you get to know HTML better, you’ll begin to see the world differently. Instead of just seeing a web page you might begin to wonder what HTML markup is actually being used. And if you dig in and look at what others have done, you might be horribly surprised to find a tangled ugly mess. Having taken this course, you are capable of writing much better markup!`}</p>
    </Subpage>
    <Subpage slug="comp-to-code" mdxType="Subpage">
      <h3>{`Markup from Comp to Code`}</h3>
      <p>{`Watch how to add markup to content based on a design comp:`}</p>
      <iframe width="560" height="315" src="https://www.youtube.com/embed/TSABgnKPoxc?list=PLuOViGmL7TfXlFl74Hf84NwSQPR_LDR0d" frameborder="0" allowfullscreen></iframe>
    </Subpage>
    <Subpage slug="block-vs-inline" mdxType="Subpage">
      <h3>{`Block-level vs. Inline Tags`}</h3>
      <Callout number="2.6" term="Block-level vs. Inline elements" color="alternate" mdxType="Callout">
        <p>{`Block-level elements such as `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`<h1>`}</code>{`–`}<code parentName="p" {...{
            "className": "language-text"
          }}>{`<h6>`}</code>{`, `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`<p>`}</code>{` fill 100% of their container and allow contents to wrap inside of them. Inline elements such as `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`<a>`}</code>{`, `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`<em>`}</code>{`, `}<code parentName="p" {...{
            "className": "language-text"
          }}>{`<strong>`}</code>{` and others only take up the space their content requires.`}</p>
      </Callout>
      <p>{`As we've covered a lot of tags so far it is important to call out one more way we categorize them. All tags are considered either block-level or inline.`}</p>
      <p><em parentName="p">{`Block-level`}</em>{` tags fill 100% of their container's width regardless of how much content they contain. So a paragraph will technically span the width of its container even if the content in that paragraph does not fill that space. But if the content does fill that space, the browser knows to wrap the text from line to line.`}</p>
      <p>{`Block-level tags we've covered so far include:`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "html"
      }}><pre parentName="div" {...{
          "style": {
            "counterReset": "linenumber NaN"
          },
          "className": "language-html line-numbers"
        }}><code parentName="pre" {...{
            "className": "language-html"
          }}><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`h1`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{` `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`h2`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{` `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`h3`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{` `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`h4`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{` `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`h5`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{` `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`h6`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{` `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`p`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{` `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`blockquote`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{` `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`ul`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{` `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`ol`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{` `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`li`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{` `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`dl`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{` `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`dt`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{` `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`dd`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span></code><span parentName="pre" {...{
            "aria-hidden": "true",
            "className": "line-numbers-rows",
            "style": {
              "whiteSpace": "normal",
              "width": "auto",
              "left": "0"
            }
          }}><span parentName="span"></span></span></pre></div>
      <p>{`And a few more we'll cover later in the course are:`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "html"
      }}><pre parentName="div" {...{
          "style": {
            "counterReset": "linenumber NaN"
          },
          "className": "language-html line-numbers"
        }}><code parentName="pre" {...{
            "className": "language-html"
          }}><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`div`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{` `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`header`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{` `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`footer`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{` `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`article`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{` `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`aside`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{` `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`nav`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{` `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`section`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span></code><span parentName="pre" {...{
            "aria-hidden": "true",
            "className": "line-numbers-rows",
            "style": {
              "whiteSpace": "normal",
              "width": "auto",
              "left": "0"
            }
          }}><span parentName="span"></span></span></pre></div>
      <p><em parentName="p">{`Inline tags`}</em>{` only occupy the width that their content requires. So an `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`<em>`}</code>{` tag only takes up as much space as the words inside that tag take up; an image will naturally take up as much space as needed based on its pixel width and height.`}</p>
      <p>{`Inline tags covered so far are:`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "html"
      }}><pre parentName="div" {...{
          "style": {
            "counterReset": "linenumber NaN"
          },
          "className": "language-html line-numbers"
        }}><code parentName="pre" {...{
            "className": "language-html"
          }}><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`b`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{` `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`i`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{` `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`em`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{` `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`strong`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{` `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`q`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{` `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`cite`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{` `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`abbr`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{` `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`sub`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{` `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`sup`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{`, etc.`}</code><span parentName="pre" {...{
            "aria-hidden": "true",
            "className": "line-numbers-rows",
            "style": {
              "whiteSpace": "normal",
              "width": "auto",
              "left": "0"
            }
          }}><span parentName="span"></span></span></pre></div>
      <p>{`And others we'll cover later in the course are:`}</p>
      <div {...{
        "className": "gatsby-highlight",
        "data-language": "html"
      }}><pre parentName="div" {...{
          "style": {
            "counterReset": "linenumber NaN"
          },
          "className": "language-html line-numbers"
        }}><code parentName="pre" {...{
            "className": "language-html"
          }}><span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`a`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`>`}</span></span>{` `}<span parentName="code" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token tag"
              }}><span parentName="span" {...{
                  "className": "token punctuation"
                }}>{`<`}</span>{`img`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`/>`}</span></span></code><span parentName="pre" {...{
            "aria-hidden": "true",
            "className": "line-numbers-rows",
            "style": {
              "whiteSpace": "normal",
              "width": "auto",
              "left": "0"
            }
          }}><span parentName="span"></span></span></pre></div>
      <p>{`This distinction is important to note because it will have an impact on the CSS properties that can be used with these elements, which we will discuss later in the course. For now, ensure you can can categorize tags as they've been distinguished in this module.`}</p>
    </Subpage>
    <Subpage slug="study-tools" mdxType="Subpage">
      <h3>{`Study Tools`}</h3>
      <p>{`The application activity that accompanies this module challenges you to apply typography and faux hyperlinks to content from static design comps. Review the first video in this module for a demonstration of this process, and ensure that you can do the following:`}</p>
      <ul>
        <li parentName="ul">{`Recognize the difference between paragraphs, headings, and lists.`}</li>
        <li parentName="ul">{`Analyze the structure of a set of content, particularly the structure of headings from primary to secondary, to tertiary and beyond.`}</li>
        <li parentName="ul">{`Recognize lists in unusual formats such as inline or in grids.`}</li>
        <li parentName="ul">{`Apply inline markup to add more refined meaning to content.`}</li>
      </ul>
      <p>{`Review the key takeaways from this module. Memorize each of the following tags or attributes and understand what it is used for:`}</p>
      <h4>{`Block-level Content Tags`}</h4>
      <ul>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`<p>`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`<h1>`}</code>{` – `}<code parentName="li" {...{
            "className": "language-text"
          }}>{`<h6>`}</code>{` (each one counts as a separate tag here)`}</li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`<blockquote>`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`<ol>`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`<ul>`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`<li>`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`<dl>`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`<dd>`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`<dt>`}</code></li>
      </ul>
      <h4>{`Inline Content Tags`}</h4>
      <ul>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`<abbr>`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`<q>`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`<cite>`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`<i>`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`<b>`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`<em>`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`<strong>`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`<sup>`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`<sub>`}</code></li>
      </ul>
      <h4>{`Standalone Tags`}</h4>
      <ul>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`<br/>`}</code></li>
        <li parentName="ul"><code parentName="li" {...{
            "className": "language-text"
          }}>{`<hr/>`}</code></li>
      </ul>
    </Subpage>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      